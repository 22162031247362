//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { PrescriptionService } from '@/services/prescription.service';
import { format, fromUnixTime, getUnixTime, parse, getHours, getMinutes, getSeconds, differenceInDays, startOfDay, addDays, isBefore } from 'date-fns';
export default {
  props: {
    prescriptionData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      processing: false,
      loading: false,
      isConfirmation: false,
      selectedReason: 'Patient Request',
      newRefillDate: new Date(),
      cachedRefillDate: new Date(),
      notes: '',
      hasBothProgesteroneEstrogen: false,
      // Add product id here when there are new medicines
      progesterone: process.env.VUE_APP_SERVER === 'production' ? ['prod_J8qskZM95d4h1B', 'prod_J8qk9U4MguDLC7', 'prod_J8r4ayn4y85ADP'] : ['prod_IL5QKdMlYT65Zs', 'prod_I7yojhB1EczL4O', 'prod_I7z1aI75Fo7SiS'],
      estrogen: process.env.VUE_APP_SERVER === 'production' ? ['prod_J8rKp41ar65JIA', 'prod_J8rFoA22Ysb9pj', 'prod_J8r9u00ipD3Bbx', 'prod_J8r4ayn4y85ADP'] : ['prod_IL4jRN5xEf9CDV', 'prod_IMc85eAvetjSrw', 'prod_IAXvWO6QC4p2XC', 'prod_I7z1aI75Fo7SiS'],
      remainingPrescriptions: [],
      dayDifference: 0
    };
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.newRefillDate = format(fromUnixTime(this.prescriptionData.nextshipmentdate), 'yyyy-MM-dd');
        this.cachedRefillDate = fromUnixTime(this.prescriptionData.nextshipmentdate);
      } else {
        this.isConfirmation = false;
        this.hasBothProgesteroneEstrogen = false;
      }
    },

    'prescriptionData.ongoingItems': {
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          const hasProgesterone = val.some(item => this.progesterone.includes(item.productid));
          const hasEstrogen = val.some(item => this.estrogen.includes(item.productid));
          this.hasBothProgesteroneEstrogen = hasProgesterone && hasEstrogen && [...this.progesterone, ...this.estrogen].includes(this.prescriptionData.productid);
        }
      },

      deep: true
    },

    hasBothProgesteroneEstrogen(val) {
      if (val) {
        this.remainingPrescriptions = this.prescriptionData.ongoingItems.filter(item => {
          return [...this.progesterone, ...this.estrogen].includes(item.productid) && this.prescriptionData.productid !== item.productid;
        });
      }
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },

    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    },

    dateDisabled(ymd, date) {
      return new Date() > date;
    },

    async onChangeRefillDate() {
      this.alert.show = false;

      try {
        this.processing = true;
        await PrescriptionService.updateShipmentDate(this.prescriptionData.patientid, this.prescriptionData.id, {
          nextshipmentdate: getUnixTime(parse(this.getDateStringWithCurrentTime(this.newRefillDate), 'yyyy-MM-dd H:m:s', new Date())),
          reason: this.selectedReason,
          notes: this.notes
        });

        if (this.hasBothProgesteroneEstrogen && !this.prescriptionData.hysterectomy) {
          const updateShipmentDatePromises = this.remainingPrescriptions.map(prescription => {
            return PrescriptionService.updateShipmentDate(prescription.patientid, prescription.id, {
              nextshipmentdate: getUnixTime(parse(this.getDateStringWithCurrentTime(prescription.newrefilldate), 'yyyy-MM-dd H:m:s', new Date())),
              reason: this.selectedReason,
              notes: this.notes
            });
          });
          await Promise.all(updateShipmentDatePromises);
        }

        this.$emit('refresh-prescriptions');
        this.processing = false;
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    },

    async onRushPrescription() {
      this.alert.show = false;

      try {
        this.processing = true;
        await PrescriptionService.bypassDelay(this.prescriptionData.id);
        this.$emit('refresh-prescriptions');
        this.processing = false;
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    },

    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(currentDate)}:${getSeconds(currentDate)}`;
    },

    onChangeDate() {
      this.isConfirmation = true;
      this.dayDifference = differenceInDays(parse(this.newRefillDate, 'yyyy-MM-dd', new Date()), startOfDay(fromUnixTime(this.prescriptionData.nextshipmentdate)));
      this.remainingPrescriptions = this.remainingPrescriptions.map(item => {
        const newRefillDate = addDays(fromUnixTime(item.nextshipmentdate), this.dayDifference);

        if (isBefore(newRefillDate, addDays(new Date(), 1))) {
          return { ...item,
            newrefilldate: format(addDays(new Date(), 1), 'yyyy-MM-dd')
          };
        } else {
          return { ...item,
            newrefilldate: format(newRefillDate, 'yyyy-MM-dd')
          };
        }
      });
    }

  }
};